import React, { Fragment, useEffect, useState } from "react"
import "fontsource-nunito"
import "fontsource-lato"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Link as RouterLink, useI18next } from "gatsby-plugin-react-i18next"

import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles"
import {
  CssBaseline,
  Container,
  Box,
  Typography,
  Grid,
  Link as StyleLink,
  Button,
  Hidden,
  Menu,
  MenuItem,
  Card,
  CardContent,
  IconButton,
  Fab,
} from "@material-ui/core"
import {
  Menu as MenuIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  KeyboardArrowUp as ToTopIcon,
} from "@material-ui/icons"

import Animate from "src/components/animate"
import Newsletter from "src/components/newsletter"

import translate from "src/functions/translate"

let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#81a6d3",
      main: "#6290C8",
      dark: "#44648c",
      contrastText: "#fff",
    },
    secondary: {
      light: "#b494a9",
      main: "#a27a94",
      dark: "#715567",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Lato, Roboto, sans-serif",
    h1: {
      fontFamily: "Nunito, Roboto, sans-serif",
    },
    h2: {
      fontFamily: "Nunito, Roboto, sans-serif",
    },
    h3: {
      fontFamily: "Nunito, Roboto, sans-serif",
    },
    h4: {
      fontFamily: "Nunito, Roboto, sans-serif",
    },
    h5: {
      fontFamily: "Nunito, Roboto, sans-serif",
    },
    h6: {
      fontFamily: "Nunito, Roboto, sans-serif",
    },
  },
})
theme = responsiveFontSizes(theme)

const Layout = ({ children }) => {
  useEffect(() => {
    console.log("=== Layout ===")
  })

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { regex: "/logo.png/" }) {
        childImageSharp {
          fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      strapi {
        menu {
          links {
            id
            title_fr
            title_en
            visible
            url
            anchor
            page {
              slug
              title_fr
              title_en
            }
          }
        }
      }
    }
  `)

  const [anchorEl, setAnchorEl] = useState(null)

  const openMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const NavButton = props => (
    <Box marginX={1}>
      <Button
        color="primary"
        size="large"
        href={props.anchor || props.url}
        target={props.url && "_blank"}
        aria-label={props.title}
        component={props.to ? RouterLink : Button}
        to={props.to}
      >
        <Box letterSpacing={3} fontWeight={600}>
          {props.title}
        </Box>
      </Button>
    </Box>
  )

  const NavMobileButton = props => (
    <Fragment>
      <Button
        color="primary"
        size="large"
        href={props.anchor || props.url}
        target={props.url && "_blank"}
        aria-label={props.title}
        component={props.to ? RouterLink : Button}
        to={props.to}
      >
        <Box letterSpacing={3} fontWeight={600}>
          {props.title}
        </Box>
      </Button>
    </Fragment>
  )

  const menuLinks = data.strapi.menu.links

  const { language, languages, originalPath } = useI18next()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Newsletter />

      <Box id="top" />

      {/* START : Mobile menu */}
      <Hidden smUp>
        <Box position="absolute" top={theme.spacing(1)} left={theme.spacing(1)}>
          <Button
            color="primary"
            size="large"
            startIcon={<MenuIcon />}
            onClick={openMenu}
            aria-label="menu"
          >
            <Box fontWeight={600}>menu</Box>
          </Button>

          <Menu
            id="menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              {menuLinks.map(
                link =>
                  link.visible && (
                    <MenuItem onClick={closeMenu} key={link.id}>
                      <NavMobileButton
                        title={
                          translate(link, "title", language) ||
                          (link.page && translate(link.page, "title", language))
                        }
                        to={link.page && "/" + link.page.slug}
                        anchor={link.anchor && "#" + link.anchor}
                        url={link.url}
                      />
                    </MenuItem>
                  )
              )}
            </Box>
          </Menu>
        </Box>
      </Hidden>
      {/* STOP : Mobile menu */}

      {/* START : Top icon links */}
      <Box
        position="absolute"
        top={theme.spacing(1)}
        right={theme.spacing(1)}
        display="flex"
        flexDirection="column"
      >
        {/* START : Languages */}
        {languages.map(lang => {
          if (lang === language) {
            return null
          } else {
            return (
              <IconButton
                key={lang}
                color="secondary"
                style={{ padding: theme.spacing(1) }}
                aria-label="Language"
                component={RouterLink}
                to={originalPath}
                language={lang}
              >
                <Typography
                  variant="caption"
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  {lang}
                </Typography>
              </IconButton>
            )
          }
        })}
        {/* STOP : Languages */}

        {/* START : Social links */}
        <IconButton
          color="primary"
          href="https://www.facebook.com/profile.php?id=100059128698307"
          target="_blank"
          rel="noopener noreferrer"
          style={{ padding: theme.spacing(1) }}
          aria-label="Facebook"
        >
          <FacebookIcon fontSize="small" />
        </IconButton>
        <IconButton
          color="primary"
          href="https://www.instagram.com/carolineyoga20/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ padding: theme.spacing(1) }}
          aria-label="Instagram"
        >
          <InstagramIcon fontSize="small" />
        </IconButton>
        <IconButton
          color="primary"
          href="https://www.linkedin.com/in/caroline-martin-429743148/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ padding: theme.spacing(1) }}
          aria-label="LinkedIn"
        >
          <LinkedInIcon fontSize="small" />
        </IconButton>
        {/* STOP : Social links */}
      </Box>
      {/* STOP : Top icon links */}

      {/* START : Header */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center"
        height="30vh"
      >
        {/* START : Logo */}
        <Box marginY={1} minWidth="80px" minHeight="80px">
          <RouterLink to="/">
            <Img
              fluid={data.logo.childImageSharp.fluid}
              alt="Caroline.Yoga"
              title="Caroline.Yoga"
            />
          </RouterLink>
        </Box>
        <Box marginY={1} textAlign="center" color="secondary.main">
          <Container>
            <StyleLink
              color="inherit"
              component={RouterLink}
              to="/"
              underline="none"
            >
              <Typography variant="h3" variantMapping={{ h3: "h1" }}>
                Caroline.Yoga
              </Typography>
            </StyleLink>
          </Container>
        </Box>
        {/* STOP : Logo */}

        {/* START : Desktop menu */}
        <Hidden xsDown>
          <Box marginY={1}>
            <Container>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                {menuLinks.map(
                  link =>
                    link.visible && (
                      <Grid item key={link.id}>
                        <NavButton
                          title={
                            translate(link, "title", language) ||
                            (link.page &&
                              translate(link.page, "title", language))
                          }
                          to={link.page && "/" + link.page.slug}
                          anchor={link.anchor && "#" + link.anchor}
                          url={link.url}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            </Container>
          </Box>
        </Hidden>

        {/* STOP : Desktop menu */}
      </Box>
      {/* STOP : Header */}

      <Box>{children}</Box>

      {/* START : Contact */}
      <Container>
        <Box
          id="contact"
          marginY={8}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          style={{
            minHeight: "30vh",
          }}
        >
          <Box marginY={2} textAlign="center" color="secondary.main">
            <Typography variant="h4">Contact</Typography>
          </Box>

          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Animate animation="zoom-in" duration="0">
                <Box margin={2} textAlign="center">
                  <Card variant="outlined">
                    <CardContent>
                      <Box margin={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}>
                        <Typography variant="h6" variantMapping={{ h6: "h5" }}>
                          Caroline MARTIN
                          <br />
                          +41 (0)78 692 31 30
                          <br />
                          +33 (0)6 59 62 67 11
                          <br />
                          <StyleLink
                            href="mailto:contact@caroline.yoga"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            contact@caroline.yoga
                          </StyleLink>
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Animate>
            </Grid>
          </Grid>
        </Box>
      </Container>
      {/* STOP : Contact */}

      {/* START : Footer */}
      <Box textAlign="center">
        <StyleLink href="#top">
          <Fab color="primary" size="small" aria-label="Scroll top section">
            <ToTopIcon />
          </Fab>
        </StyleLink>
      </Box>
      <Box marginY={{ xs: 2, sm: 4, md: 6, lg: 8, xl: 10 }}>
        <Typography align="center">
          www.caroline.yoga - création web {new Date().getFullYear()} :{` `}
          <StyleLink
            href="https://pro.guslyon.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            GusLyon.fr
          </StyleLink>
        </Typography>
      </Box>
      {/* STOP : Footer */}
    </ThemeProvider>
  )
}

export default Layout
