import React from "react"

import addToMailchimp from "gatsby-plugin-mailchimp"

import { format } from "date-fns"
import frLocale from "date-fns/locale/fr"

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
} from "@material-ui/core"

const Newsletter = () => {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [confirm, setConfirm] = React.useState(false)
  const [fields, setFields] = React.useState({})

  const openPopup = () => {
    setOpen(true)
  }

  const closePopup = () => {
    setOpen(false)
  }

  const updateField = e => {
    const nextFields = {
      ...fields,
      [e.target.id]: e.target.value,
    }
    // console.log("nextFields", nextFields)
    setFields(nextFields)
  }

  const saveSubscription = () => {
    if (fields.email) {
      setLoading(true)

      addToMailchimp(fields.email, {
        FNAME: fields.firstname,
        LNAME: fields.lastname,
        "group[21828][1]": "1",
      }).then(data => {
        console.log(data)
        if (data.result === "success") {
          typeof window !== "undefined" &&
            localStorage.setItem("newsletter", 999)
          setConfirm(true)
        } else {
          alert(data.msg)
        }
        setLoading(false)
      })
    }
  }

  if (typeof window !== "undefined") {
    // Visits frequency based on Fibonacci sequence
    const visits = [1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987]
    const newsletter = Number(localStorage.getItem("newsletter"))
    const date = localStorage.getItem("date")
    const now = format(new Date(), "yyyy-MM-dd", { locale: frLocale })
    localStorage.setItem("date", now)

    if (date && newsletter) {
      // If visited
      if (date === now) {
        // If visited today
      } else {
        // If visited an other day
        localStorage.setItem("newsletter", newsletter + 1)
        if (visits.includes(newsletter + 1)) {
          // If frequency included
          openPopup()
        }
      }
    } else {
      // If first visit
      openPopup()
      localStorage.setItem("newsletter", 1)
    }
  }

  return (
    <Dialog open={open} onClose={closePopup} maxWidth="xs" fullWidth>
      <DialogContent style={{ padding: 4 * 8 }}>
        <Box marginBottom={2}>
          <Typography color="secondary" variant="h5">
            S'inscrire à la newsletter
          </Typography>
        </Box>
        {!confirm ? (
          <React.Fragment>
            <Box marginBottom={2}>
              <TextField
                id="firstname"
                label="Prénom"
                type="text"
                fullWidth
                variant="outlined"
                onChange={updateField}
              />
            </Box>
            <Box marginBottom={2}>
              <TextField
                id="lastname"
                label="Nom"
                type="text"
                fullWidth
                variant="outlined"
                onChange={updateField}
              />
            </Box>
            <Box marginBottom={2}>
              <TextField
                id="email"
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                onChange={updateField}
              />
            </Box>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={saveSubscription}
              disabled={!fields.email}
              style={{ padding: 16 }}
            >
              {loading ? (
                <React.Fragment>
                  <CircularProgress size={20} color="inherit" />
                  <Box component="span" style={{ marginLeft: 8 }}>
                    Envoi en cours...
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>S'inscrire</React.Fragment>
              )}
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box marginY={4}>
              <DialogContentText>
                Merci pour votre inscription !
              </DialogContentText>
            </Box>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={closePopup}
              style={{ padding: 16 }}
            >
              Fermer
            </Button>
          </React.Fragment>
        )}
        <Box marginTop={4}>
          <DialogContentText>
            Votre adresse e-mail ne sera jamais cédée ni revendue. En vous
            inscrivant ici, vous recevrez des infos concernant le yoga et les
            activités que je propose. Vous pouvez vous désabonner à tout
            instant.
          </DialogContentText>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default Newsletter
