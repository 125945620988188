import React from "react"

const Animate = props => (
  <div
    data-sal={props.animation || "fade"}
    data-sal-duration={props.duration || "1000"}
    data-sal-delay="300"
    data-sal-easing="ease"
  >
    {props.children}
  </div>
)

export default Animate
